import "./contactUsScreen.css";
import Select from "react-select";
import { useState } from "react";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUsScreen() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const [showError, setShowError] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [captcha, setCaptcha] = useState(null);

  const serverAPICallBack = useServerAPICalls();

  const options = [
    { value: "general", label: "General Enquiry" },
    { value: "delivery", label: "Delivery Details" },
    { value: "returns", label: "Product Return" },
    { value: "sell", label: "Become a Seller" },
    { value: "other", label: "Other" },
  ];

  function onCaptchaVerification(value) {
    //console.log("Captcha value:", value);
    setCaptcha(value);
  }

  const sendFormDetails = async () => {
    const data = await serverAPICallBack("post", "contact", {
      captcha,
      type: "customer",
      name,
      email,
      message,
      reason: selectedOption.value,
    });
    if (data.isError) {
      setShowServerError(true);
      return;
    } else {
      setIsSuccess(true);
    }
  };

  const submitFormHandler = async () => {
    //console.log("Contact form handler");

    if (!selectedOption) {
      //console.log("here");

      setShowError(true);
      return;
    }
    if (email === "") {
      //console.log("here2");
      setShowError(true);
      return;
    }
    if (message === "") {
      //console.log("here3");
      setShowError(true);
      return;
    }
    if (!captcha) {
      alert("Please verify the captcha");
      return;
    }
    setShowError(false);
    sendFormDetails();
  };

  return (
    <div className="contactUsContainer">
      <div className="contactUsHeading">Contact Us Form</div>
      {isSuccess ? (
        <div className="contactFormSuccessMessage">
          Your Message Received! We will get back to you on the email provided!
        </div>
      ) : (
        <>
          <div className="contactUsSubHeading">
            Please write your message below and we will get back to you!{" "}
          </div>
          <div className="contactFormContainer">
            <div className="contactInlineText"> Reason*</div>
            <Select
              placeholder="Please Select"
              options={options}
              onChange={setSelectedOption}
              className={"reasonSelect"}
            />
            <div className="contactEmailInputRow">
              <div className="contactInlineText">Name</div>
              <input
                className="contactFormEmailInput"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="contactEmailInputRow">
              <div className="contactInlineText">Email*</div>
              <input
                className="contactFormEmailInput"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="contactEmailInputRow">
              <div className="contactInlineText"> Message* </div>
              <textarea
                className="contactFormMessageText"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
            {showError && (
              <div className="contactFormError">
                Error! Please provide Reason, Email and Message!
              </div>
            )}
            {showServerError && (
              <div className="contactFormError">
                Oops! something went wrong, please refresh and try again.
              </div>
            )}
            <div className="contactUsCaptchaBox">
              <ReCAPTCHA
                //sitekey="6LdvwIckAAAAACvnmmU2nv9SjTpjebRw2dzNT6Mb"
                sitekey="6Ldueu0kAAAAALNNOmehsaJc-Ftfh1IoqhvvzTNZ"
                onChange={onCaptchaVerification}
              />
            </div>
            <div
              className="contactFormSubmitBtn"
              onClick={() => {
                submitFormHandler();
              }}
            >
              Submit
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ContactUsScreen;
