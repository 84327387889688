import "./hero.css";
import LogoHero from "../Logos/LogoHero";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function Hero() {
  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  const navigate = useNavigate();

  return (
    <>
      <div className="containerHero">
        <LogoHero />
        <span className="heroTextHeading">Reasons to Love Us</span>
        <div className="textHeroContainer">
          <span>
            Get Confirmation within 24 hours | Great Value | No upfront Payment
            | Free Returns up to 30 Days | Brand New Products
          </span>
        </div>
        <Link
          to={"/howitworks"}
          className="heroNoDecorationLink"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "howItWorkLinkHero",
                //field: JSON.stringify({ email }),
              },
            });
          }}
        >
          <div className="howItWorksHero">How it works ?</div>
        </Link>
        <div
          className="sellWithUsHero"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "sellWithUsHeroLink",
              },
            });
            navigate("seller");
          }}
        >
          Sell with us for Free
        </div>
      </div>
    </>
  );
}

export default Hero;
