import { IoCartOutline } from "react-icons/io5";
import "./logoHeader.css";
import logoCart from "./logoCart.png";

function LogoHeader() {
  return (
    <div className="logoHeaderContainer">
      <div className="logoHeaderBox">
        <div className="logoHeaderTriangle1"></div>
        <div className="logoTextContainer">
          <div>Lot</div>
          <div>Cheaper</div>
        </div>
        <div className="cartIconLogoHeader">
          <IoCartOutline size={"1.5rem"} />
        </div>
        <div className="circleLogoHeader"></div>
      </div>
      <div className="logoHeaderTriangle2"></div>
    </div>
  );
}

export default LogoHeader;
