import { useContext } from "react";
import CartItem from "../../components/cart/CartItem";
import CartSummaryBox from "../../components/cart/CartSummaryBox";
import CheckoutButton from "../../components/cart/CheckoutButton";
import BackButton from "../../components/ui/BackButton";
import { CartContext } from "../../store/cartContext";
import "./cart.css";

function Cart() {
  const { cart, setCart } = useContext(CartContext);
  return (
    <div className="cartPageContainer">
      <BackButton />
      {!cart || cart.length === 0 ? (
        <div className="cartEmptyMsg">Oops...No Items in the Cart !</div>
      ) : (
        <>
          <div className="cartHeading">Your Cart</div>

          <CartSummaryBox cart={cart} />
          <div className="cartNotPayNowNotice">*No payment needed now</div>
          <div className="cartNotPayNowNotice">
            You can cancel your order even after confirmation
          </div>
          <div className="cartTableHeading">
            <div className="productHeading">Product</div>
            <div className="quantityHeading">Quantity</div>
            <div className="priceHeading">Price</div>
            <div className="totalHeading">Total</div>
          </div>
          <div className="cartBox">
            {cart.map((cartItem, idx) => (
              <CartItem item={cartItem} key={idx} />
            ))}
          </div>
          <CheckoutButton />
        </>
      )}
    </div>
  );
}

export default Cart;
