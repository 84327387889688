import { IoCartOutline } from "react-icons/io5";
import "./logoHero.css";
import logoCart from "./logoCart.png";

function LogoHero() {
  return (
    <div>
      <div className="outerContainerMain">
        <div className="logoHeroBox">
          <div className="logoHeroIconTextCol">
            <div className="logocartIconHero">
              {/* <IoCartOutline size={"1.5rem"} /> */}
              <img src={logoCart} style={{ width: "50px" }} />
              <div className="logoHeroTextBox">LotCheaper.Com</div>
            </div>
          </div>
          <div className="logoHeroTriangle1"></div>
          <div className="logoHeroTriangle2"></div>
          <div className="circleLogoHero"></div>
        </div>
      </div>
    </div>
  );
}

export default LogoHero;
