import { useLocation } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import "./checkoutSummaryBox.css";

function CheckoutSummaryBox({ cart, clickHandler }) {
  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  return (
    <div className="checkoutProceedToCheckoutContainer">
      <div className="checkoutCartSummaryBox">
        <div className="checkoutSummaryRow">Summary</div>
        <div className="checkoutQtyTotRow">
          Total Items :
          {cart
            .reduce(
              (qtyTot, curr) => (qtyTot = Number(qtyTot) + Number(curr.qty)),
              0
            )
            .toLocaleString()}
        </div>
        <div className="checkoutGrandTotRow">
          Total Savings : £
          {cart
            .reduce(
              (grandTot, curr) =>
                (grandTot =
                  Number(grandTot) +
                  Number(curr.qty) * Number(curr.origPrice - curr.offerPrice)),
              0
            )
            .toLocaleString()}
        </div>
        <div className="checkoutGrandTotRow">
          Grand Total : £
          {cart
            .reduce(
              (grandTot, curr) =>
                (grandTot =
                  Number(grandTot) +
                  Number(curr.qty) * Number(curr.offerPrice)),
              0
            )
            .toLocaleString()}
        </div>
        <span className="checkoutSummaryLinkNoDecoration" to="/checkout">
          <div
            className="placeOrderSummaryBtn"
            onClick={() => {
              analyParamsCB({
                type: "click",
                pl: {
                  pn: location.pathname,
                  clk: "placeOrderBtnCheckoutSummaryBox",
                },
              });
              clickHandler();
            }}
          >
            Place Order
          </div>
        </span>
      </div>
    </div>
  );
}

export default CheckoutSummaryBox;
