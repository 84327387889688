import React from "react";
import BestDeals from "../../components/bestDeals/BestDeals";
import BrandsRow from "../../components/brandsRow/BrandsRow";
import Categories from "../../components/categoriesGrid/Categories";
import Hero from "../../components/hero/Hero";
import ProductGrid from "../../components/productGrid/ProductGrid";
import SearchProductBtn from "../../components/searchProductRow/SearchProductBtn";

function Home() {
  return (
    <div>
      <Hero />
      <BestDeals />
      <BrandsRow />
      <Categories />
      <ProductGrid />
      <SearchProductBtn />
    </div>
  );
}

export default Home;
