import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import "./footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  return (
    <div className="footerContainer">
      {" "}
      <div className="copyrightDiv">
        <div className="footerLinksContainer">
          <div className="footerLinksBox">
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "homeLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/");
              }}
            >
              <div className="footerlink">Home</div>
            </span>
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "cartLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/cart");
              }}
            >
              <div className="footerlink">Cart</div>
            </span>
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "accountLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/account");
              }}
            >
              <div className="footerlink">Account</div>
            </span>
          </div>

          <div className="footerLinksBox">
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "contactLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/contact");
              }}
            >
              <div className="footerlink">Contact Us</div>
            </span>
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "sellerLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/seller");
              }}
            >
              <div className="footerlink">Become a Seller</div>
            </span>
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "howitworksLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/howitworks");
              }}
            >
              <div className="footerlink">How it works</div>
            </span>
          </div>
          <div className="footerLinksBox">
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "inviteLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/account?pg=invite", {
                  state: {
                    ref: Math.floor(Math.random() * (999 - 100 + 1) + 100),
                  },
                });
              }}
            >
              <div className="footerlink">Invite & Earn</div>
            </span>
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "privacyLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/privacy");
              }}
            >
              <div className="footerlink">Privacy Policy</div>
            </span>
            <span
              className="footerNoDecoratonLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "termsLinkFooter",
                    //field: JSON.stringify({ email }),
                  },
                });
                navigate("/terms");
              }}
            >
              <div className="footerlink">Terms & Conditions</div>
            </span>
          </div>
        </div>
        <div className="footerCopyRightLine">
          Copyright&copy;2023 LotCheaper.com
        </div>
      </div>
    </div>
  );
};

export default Footer;
