import "./category.css";
import { useParams } from "react-router-dom";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { useContext, useEffect, useState } from "react";
import ProductBox from "../../components/productBox/ProductBox";
import BackButton from "../../components/ui/BackButton";
import DropDownButton from "../../components/ui/DropDownButton";
import { ShoesContext } from "../../store/shoesContext";

// ***************************************************************************************************************
// TODO: for the products like shoes we sould have create a new type or edit  of productBox, which allows size selection. So that way we dont have to worry about an extra size filter and creation of so many products just based on size
// TODO: there should be a flaga at a product level that will tell whether we should show size or not
// TODO: we defintely need to keep the filters like gender  in place though, but that should be implemented via URL parameters instead of states
// ***************************************************************************************************************

function Category() {
  const [prods, setProds] = useState([]);
  const [prodToShow, setProdToShow] = useState([]); // this is in case you have more than 50 products in category. May be you can use only this instead of using prod array
  const [prodLoadCounter, setProdLoadCounter] = useState(1);
  const [gender, setGender] = useState();
  const [colour, setColour] = useState();
  const [size, setSize] = useState();
  const {
    genderCtx,
    setGenderCtx,
    sizeCtx,
    setSizeCtx,
    colourCtx,
    setColourCtx,
  } = useContext(ShoesContext);

  const params = useParams();
  const categoryName = params.category;

  const serverApiCallback = useServerAPICalls();

  const showProductsListRandomly = (products) => {
    setProdToShow(
      products
        .map((prod) => ({ prod, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ prod }) => prod)
        .slice(0, prodLoadCounter * 50 - 1)
    );
  };
  const showProductsList = (products) => {
    // console.log(
    //   "Products",
    //   products,
    //   " and the product load counter is ",
    //   prodLoadCounter
    // );

    var prodList = gender
      ? products.filter((prod) => prod.filter1Value === gender)
      : products;

    //console.log("The prod List after gender set is ", prodList);

    //colour ? console.log("Colour", colour) : console.log("no colour");
    prodList =
      colour && colour !== "All"
        ? prodList.filter((prod) => prod.filter3Value === colour)
        : prodList;
    //console.log("The prod List after Colour set is ", prodList);

    prodList = size
      ? prodList.filter((prod) => prod.filter2Value === String(size))
      : prodList;
    setProdToShow(
      prodList.map((prod) => prod).slice(0, prodLoadCounter * 50 - 1)
    );
  };

  const getProducts = async () => {
    //console.log("Get Products called for Category ", categoryName);
    const data = await serverApiCallback(
      "get",
      "products/category/" + categoryName,
      {}
    );
    //console.log("The data returned is ", data.data);
    setProds(data.data);
    showProductsListRandomly(data.data);
  };

  // This useEffect is for loading the products everytime we hit the page bottom
  useEffect(() => {
    if (prodLoadCounter < 10) {
      // console.log(//   "Inside If, the prod load counter is currently set to", prodLoadCounter);
      showProductsList(prods);
    }
  }, [prodLoadCounter]);

  // This useEffect is for loading the products everytime the filter is changed
  useEffect(() => {
    // console.log(
    //   "Filter Changed: Gender, Colour, Size are :",
    //   gender,
    //   " ",
    //   colour,
    //   " ",
    //   size
    // );

    showProductsList(prods);
    setGenderCtx(gender);
    setSizeCtx(size);
  }, [gender, colour, size]);

  useEffect(() => {
    getProducts();
    window.scrollTo(0, 0); // apparenttly this is needed otherwise the page may open a bit below based on where it was in the previous screen
    const onScroll = function () {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        //console.log("you're at the bottom of the page, load more products");
        setProdLoadCounter((prodLoadCounter) => prodLoadCounter + 1);
        // note here if you try and read any state's value it will be the default value as React assumes nothing changes as the dependency of the useEffect is []
      }
    };
    window.addEventListener("scroll", onScroll);
    // the first time we use the stored value in genderCtx if any
    setGender(genderCtx);
    setSize(sizeCtx);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="categoryPageContainer">
      <BackButton />
      <div className="resultsContainer">
        <div className="categoryNameRow">Category : {categoryName}</div>
        {categoryName === "Shoes" && (
          <div className="prodFiltersRow">
            <span className="filterRowFilterText">FILTERS</span>
            <DropDownButton
              values={["Men", "Women"]}
              label={"Gender "}
              selection={gender}
              setSelection={setGender}
            />
            <DropDownButton
              values={[
                4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11,
                11.5, 12,
              ]}
              label={"Size "}
              selection={size}
              setSelection={setSize}
            />
            <DropDownButton
              values={["Black", "White", "Grey", "Pink", "All"]}
              label={"Colour "}
              selection={colour}
              setSelection={setColour}
            />
          </div>
        )}
        <div className="productGrid">
          {prods.length > 0 &&
            // below we are checking if category is Shoes & if so then we are showing filter and sorting randomly
            (categoryName === "Shoes"
              ? prodToShow.map((prod, idx) => (
                  <ProductBox prod={prod} key={idx} />
                ))
              : prods.map((prod, idx) => <ProductBox prod={prod} key={idx} />))}
        </div>
      </div>
    </div>
  );
}

export default Category;
