import "./cartSummaryBox.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function CartSummaryBox({ cart }) {
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  return (
    <div className="proceedToCheckoutContainer">
      <div className="cartSummaryBox">
        <div className="summaryRow">Summary</div>
        <div className="qtyTotRow">
          Total Items :
          {cart
            .reduce(
              (qtyTot, curr) => (qtyTot = Number(qtyTot) + Number(curr.qty)),
              0
            )
            .toLocaleString()}
        </div>
        <div className="grandTotRow">
          Total Savings : £
          {cart
            .reduce(
              (grandTot, curr) =>
                (grandTot =
                  Number(grandTot) +
                  Number(curr.qty) * Number(curr.origPrice - curr.offerPrice)),
              0
            )
            .toLocaleString()}
        </div>
        <div className="grandTotRow">
          Grand Total : £
          {cart
            .reduce(
              (grandTot, curr) =>
                (grandTot =
                  Number(grandTot) +
                  Number(curr.qty) * Number(curr.offerPrice)),
              0
            )
            .toLocaleString()}
        </div>
        <span
          className="cartSummaryLinkNoDecoration"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "checkoutBtnCartSummaryBox",
              },
            });
            navigate("/checkout");
          }}
        >
          <div className="proceedToCheckoutBtn">Proceed to Checkout* </div>
        </span>
      </div>
    </div>
  );
}

export default CartSummaryBox;
