import { useContext, useState } from "react";
import { IoHomeOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

import CheckoutSummaryBox from "../../components/checkout/CheckoutSummaryBox";
import BackButton from "../../components/ui/BackButton";
import { CartContext } from "../../store/cartContext";
import "./checkout.css";

import ReCAPTCHA from "react-google-recaptcha";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function Checkout() {
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [isErrorSubmission, setIsErrorSubmission] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsCheckError, setTermsCheckError] = useState(false);

  const { cart, setCart } = useContext(CartContext);
  const navigate = useNavigate();
  const serverApiCallback = useServerAPICalls();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  function onCaptchaVerification(value) {
    //console.log("Captcha value:", value);
    setCaptcha(value);
  }

  const sendOrderDetails = async () => {
    //console.log("Going to send order details");
    const data = await serverApiCallback("post", "order", {
      captcha,
      items: cart.map((item) => ({ _id: item._id, qty: item.qty })),
      shipping: { name, address1, address2, city, postCode, email },
    });
    //console.log("The order data returned is ", data.data);
    if (data.isError) {
      console.log("Error: ", data.msg);
      setIsErrorSubmission(true);
      return;
    }
    setCart([]);
    navigate("/orderConfirmation");
  };

  const placeOrderHandler = async () => {
    // console.log("Place Order !!! ");
    // console.log(
    //   "The values are : ",
    //   name,
    //   address1,
    //   address2,
    //   city,
    //   postCode,
    //   email
    // );

    if (name === "") {
      alert("Please Enter Name");
      return;
    }
    if (address1 === "") {
      alert("Please Enter Address1");
      return;
    }
    if (city === "") {
      alert("Please Enter City/Town");
      return;
    }
    if (postCode === "") {
      alert("Please Enter PostCode/ZipCode");
      return;
    }
    if (email === "") {
      alert("Please Enter Email");
      return;
    }
    if (!captcha) {
      alert("Please verify the captcha");
      return;
    }
    if (!termsChecked) {
      setTermsCheckError(true);
      return;
    }
    await sendOrderDetails();
  };

  return (
    <div className="checkoutPageContainer">
      <BackButton />
      {!cart || cart.length === 0 ? (
        <div className="cartEmptyMsg">
          Oops...No Items in the Cart to Checkout !
        </div>
      ) : (
        <>
          <CheckoutSummaryBox cart={cart} clickHandler={placeOrderHandler} />
          <div className="addressBox">
            <div className="addressHeader">
              <IoHomeOutline size={"15px"} />
              <div> Delivery Address</div>
            </div>
            <div className="addressBodyMain">
              <div className="addressInputContainer">
                <div className="inputLabel">
                  Name<span className="redAsterisk">*</span>
                </div>
                <input
                  className="inputBox"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
              <div className="addressInputContainer">
                <div className="inputLabel">
                  Address Line 1<span className="redAsterisk">*</span>
                </div>
                <input
                  className="inputBox"
                  onChange={(event) => {
                    setAddress1(event.target.value);
                  }}
                />
              </div>
              <div className="addressInputContainer">
                <div className="inputLabel">Address Line 2</div>
                <input
                  className="inputBox"
                  onChange={(event) => {
                    setAddress2(event.target.value);
                  }}
                />
              </div>
              <div className="addressInputContainer">
                <div className="inputLabel">
                  City/Town<span className="redAsterisk">*</span>
                </div>
                <input
                  className="inputBox"
                  onChange={(event) => {
                    setCity(event.target.value);
                  }}
                />
              </div>
              <div className="addressInputContainer">
                <div className="inputLabel">
                  Post Code / Zip Code<span className="redAsterisk">*</span>
                </div>
                <input
                  className="inputBox"
                  onChange={(event) => {
                    setPostCode(event.target.value);
                  }}
                />
              </div>
              <div className="addressInputContainer">
                <div className="inputLabel">
                  Email<span className="redAsterisk">*</span>
                </div>
                <input
                  className="inputBox"
                  type="email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
            </div>
            {isErrorSubmission && (
              <div className="cartErrorSubmission">Error! Please try again</div>
            )}

            <div className="checkoutCaptchaBox">
              <ReCAPTCHA
                //sitekey="6LdvwIckAAAAACvnmmU2nv9SjTpjebRw2dzNT6Mb"
                sitekey="6Ldueu0kAAAAALNNOmehsaJc-Ftfh1IoqhvvzTNZ"
                onChange={onCaptchaVerification}
              />
            </div>
            <div className="termsCheckContainer">
              <label>
                <input
                  type="checkbox"
                  defaultChecked={termsChecked}
                  onChange={() => setTermsChecked(!termsChecked)}
                />
                I agree to{" "}
                <span
                  onClick={() => {
                    analyParamsCB({
                      type: "click",
                      pl: {
                        pn: location.pathname,
                        clk: "termsLinkCheckoutPage",
                      },
                    });
                    navigate("/terms");
                  }}
                  className="termsLinkcheckout"
                >
                  Terms & Conditions
                </span>
              </label>
            </div>
            {termsCheckError && (
              <div className="cartErrorSubmission">
                Please read and accept Terms and Conditions to proceed.
              </div>
            )}
            <div
              className="placeOrderBtn"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "placeOrderBtnBottom",
                  },
                });
                placeOrderHandler();
              }}
            >
              Place Order
            </div>
            <div className="disclaimerText">
              *Payment after Order Confirmation{" "}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Checkout;
