import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { CookieAccptContext } from "../../store/cookieAccptContext";
import "./cookieBanner.css";
function CookieBanner() {
  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  const navigate = useNavigate();
  const { setCookieAccpt } = useContext(CookieAccptContext);

  return (
    <>
      <div className="phantomStyle" />
      <div className="cookieBannerContainer">
        <div className="cookieBannerMsg">
          <strong>This Website uses Cookies</strong> <br />
          We use cookies and similar tools to provide our services, to
          understand how customers use our service so that we can make
          improvements. We don’t use any third party cookies for Marketing or
          Social Networking tools to identify and target you nor do we share
          your cookies or personal behaviour data with any other third party. By
          proceeding or continuing to use the website you consent to our cookies
          usage/privacy policy (Read:{" "}
          <div
            className="privacyPolicyLink"
            onClick={() => {
              analyParamsCB({
                type: "click",
                pl: {
                  pn: location.pathname,
                  clk: "privacyPolicyBanner",
                  //field: JSON.stringify({ email }),
                },
              });
              navigate("/privacy");
            }}
          >
            Privacy Policy
          </div>
          ).
          <div
            className="agreeCookieUsageBtn"
            onClick={() => {
              setCookieAccpt(true);
            }}
          >
            Agree & Continue
          </div>
        </div>
      </div>
    </>
  );
}
export default CookieBanner;
