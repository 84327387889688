import "./checkoutButton.css";
import { IoArrowForwardOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function CheckoutButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  return (
    <div className="checkoutBtnContainer">
      <span
        className="checkoutLinkNoDecoration"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "checkoutBtnCartBottom",
            },
          });
          navigate("/checkout");
        }}
      >
        <div className="checkoutBottomBtn">
          Checkout <IoArrowForwardOutline size={"15px"} />
        </div>
      </span>
    </div>
  );
}

export default CheckoutButton;
