import "./privacy.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useEffect } from "react";

function Privacy() {
  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // apparenttly this is needed otherwise the page may open a bit below based on where it was in the previous screen
  }, []);

  return (
    <div className="privacyPolicyContainer">
      <h4> Privacy Policy: </h4>
      <div className="privacyPolicyDescription">
        We at LotCheaper (“we”, or “us” or "platform") recognize the importance
        of privacy and confidentiality of personal information. That is why we
        keep it simple and use only those which we find necessary. No cookies or
        personal information is shared with third parties (unless exclusively
        mentioned). Lotcheaper.com is a business to consumer (or “B2C”) platform
        which connects and facilitates sales and purchases between business
        sellers (or “Sellers”) and consumer buyers (or “Buyers”).
        <br />
        This Privacy Policy sets out the ways in which we collect, use and
        disclose information in connection with the operation of the Platform,
        including personal information about you, Platform users, visitors and
        representatives.
        <br />
        Continuing to use the website would mean that you accept the privacy
        policy stated here.
      </div>
      <h4>Cookies Used</h4>
      <ul>
        <li className="privacyPolicyli">
          We use Essential or Necessary cookies including analytics cookies
          which are required for the proper functioning and performance
          measurement and user usage understanding of our website. Example: We
          use cookies to provide you with the right content related to
          product(s) you are interested in or to place orders with us or keep
          track of the pages and links you have visted to enhance user
          experience
        </li>
        <li className="privacyPolicyli">
          We do not use any third-partyMarketing cookies.
        </li>
        <li>We do not use any Social Networking cookies.</li>
        <li className="privacyPolicyli">
          Collection of necessary Personal data does happen for the purposes of
          placing and order completion , for shipping , Payment or Creation of
          account with us.
        </li>
      </ul>
      <h4>Processing and Storing Personal Data </h4>
      The principal purposes for which we process and store personal data
      relating to you are:
      <li className="privacyPolicyli">
        To confirm your identity in order to create your account and ensure that
        you are eligible to use the services provided on the Website.{" "}
      </li>
      <li className="privacyPolicyli">
        To process payments in connection with the services provided on the
        Website.
      </li>
      <li className="privacyPolicyli">
        To place the order and to capture the shipping details with respect to
        the order. These details are passed on the seller of the products in the
        order
      </li>
      <li className="privacyPolicyli">
        To provide you with information about your account.{" "}
      </li>
      <li className="privacyPolicyli">
        To notify you about changes to the Website and the materials on the
        Website.
      </li>
      <li className="privacyPolicyli">
        To prepare anonymised data sets. For any other purposes that you would
        reasonably expect.
      </li>
      <h4>Disclosure of Personal Data </h4>
      <div className="privacyPolicyDescription">
        Any personal data relating to you that is collected by us or our
        affiliates will be kept confidential by us and our affiliates, and we
        will not disclose it to any third parties(except for the sellers with
        who the order is placed). We may disclose certain personal data if we
        are required to do so by law, regulation or the order of court or other
        legitimate government body or arbitration panel. This includes, among
        other things, any personal data relating to you that may be requested by
        HM Revenue & Customs and the Financial Conduct Authority.
      </div>
      <h4>Contact Us</h4>
      To contact us use{" "}
      <span
        className="privacyPolicyContactUs"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "contactUsLinkPrivacy",
              //field: JSON.stringify({ email }),
            },
          });
          navigate("/contact");
        }}
      >
        contactUs
      </span>{" "}
      page.
    </div>
  );
}
export default Privacy;
