import "./productBox.css";
import { IoRemoveCircleOutline, IoAddCircleOutline } from "react-icons/io5";
import { useState } from "react";
import { useContext } from "react";
import { CartContext } from "../../store/cartContext";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function ProductBox({ prod }) {
  const [qty, setQty] = useState(1);
  const { cart, setCart } = useContext(CartContext);
  const location = useLocation();
  const navigate = useNavigate();
  const analyParamsCB = useAnlyParams();

  const addToCartHandler = () => {
    //console.log("Add to cart called for ", prod.name, " with qty ", qty);
    //console.log("The cart currently is ", cart); // the output puzzles me as first time it shows null as expected but second time onwards it shows the updated value of cart instead of previous value; even though the logging code is run prior to udpate of cart state
    // may be above behaviour could be because of log taking time and update of value taking less time than that.
    // delete this console.log("Testing ..........");

    //1/2 check if the product exits in the array
    var prodExists = false;
    if (cart && cart.length > 0) {
      prodExists =
        cart.map((item) => item._id).indexOf(prod._id) > -1 ? true : false;
    }

    //console.log("Prod Exits in cart already ? ", prodExists);

    //2/2 if it exits then update the qty else add the item
    if (prodExists) {
      // update the qty
      setCart(
        cart.map((item) => {
          item._id === prod._id && (item.qty = Number(item.qty) + Number(qty));
          return item;
        })
      );
    } else {
      //insert the product
      setCart(cart ? [...cart, { ...prod, qty }] : [{ ...prod, qty }]);
    }
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: "addToCartProductBox",
        field: prod.name,
      },
    });

    toast.success("Added to Cart", {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <div className="productBox">
      <span
        className="productImageLinkNoDecoration"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "imgProdHome",
              field: JSON.stringify(prod.name),
            },
          });
          navigate("/product/" + prod._id);
        }}
      >
        <img
          className="topProductImg"
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/prodImages/prod_" +
            prod.productId +
            "/" +
            prod.productId +
            "_1.jpg"
          }
          alt={
            "http://192.168.1.133:5566/uploads/prodImages/prod_" +
            prod.productId +
            "/" +
            prod.productId +
            "_1.jpg"
          }
        />
      </span>
      <span
        className="prodNameLink"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "prodTitleHome",
              field: prod.name,
            },
          });
          navigate("/product/" + prod._id);
        }}
      >
        <div className="">
          {prod.name.length > 50 ? prod.name.slice(0, 50) + "..." : prod.name}
        </div>
      </span>
      <div className="prodSize">
        {prod.feature1Name} : {prod.feature1Value}
      </div>
      {prod.feature2Name && (
        <div className="prodSize">
          {prod.feature2Name} : {prod.feature2Value}
        </div>
      )}
      <div className="prodPriceRow">
        <div className="origPrice">
          £ {Number(prod.origPrice).toLocaleString()}
        </div>
        <div className="offerPrice">
          £ {Number(prod.offerPrice).toLocaleString()}
        </div>
      </div>
      <div className="prodSaving">
        £{(Number(prod.origPrice) - Number(prod.offerPrice)).toLocaleString()}{" "}
        Off
      </div>
      <div className="prodCartRow">
        <IoRemoveCircleOutline
          size={"20px"}
          onClick={() => setQty((qty) => Math.max(qty - 1, 1))}
        />
        <input
          className="qtyInput"
          type="text"
          value={qty}
          onChange={(event) => {
            setQty(
              event.target.value > 10
                ? 10
                : event.target.value === "0"
                ? 1
                : event.target.value
            );
          }}
          onBlur={(event) => {
            qty === "" && setQty(Number(1));
          }}
        />
        <IoAddCircleOutline
          size={"20px"}
          onClick={() => setQty((qty) => Math.min(qty + 1, 10))}
        />
        <div className="addToCartButton" onClick={() => addToCartHandler()}>
          Add To Cart
        </div>
      </div>
    </div>
  );
}

export default ProductBox;
