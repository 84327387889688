import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductBox from "../../components/productBox/ProductBox";
import BackButton from "../../components/ui/BackButton";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import "./searchScreen.css";

function SearchScreen() {
  const [prods, setProds] = useState([]);
  const params = useParams();
  const serverApiCallback = useServerAPICalls();

  const getSearchResults = async () => {
    console.log("Get Productsfor keyword ", params.keyword);
    const data = await serverApiCallback("get", "search/" + params.keyword, {});
    console.log("The search data Returned is ", data.data);
    setProds(data.data);
  };

  useEffect(() => {
    getSearchResults();
  }, [params.keyword]);
  return (
    <div className="categoryPageContainer">
      <BackButton />
      <div className="resultsContainer">
        Products for search term : {params.keyword}
        <div className="productGrid">
          {prods.length > 0 &&
            prods.map((prod, idx) => <ProductBox prod={prod} key={idx} />)}
        </div>
      </div>
    </div>
  );
}

export default SearchScreen;
