import "./cartItem.css";
import {
  IoRemoveCircleOutline,
  IoAddCircleOutline,
  IoTrashBinOutline,
} from "react-icons/io5";
import { CartContext } from "../../store/cartContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function CartItem({ item }) {
  const [qty, setQty] = useState(item.qty);
  const { cart, setCart } = useContext(CartContext);

  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  useEffect(() => {
    if (cart) {
      setCart((crt) => {
        return crt.map((i) => (i._id === item._id ? { ...i, qty: qty } : i));
      });
    }
  }, [qty]);

  const handleDeleteItem = () => {
    if (window.confirm("Delete " + item.name + " ?")) {
      setCart((crt) => crt.filter((i) => i._id !== item._id));
    }
  };

  return (
    <div className="cartItemContainer">
      <span
        className="noDecorationLinkCart"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "prodImgCartItem",
              field: item.name,
            },
          });
          navigate("/product/" + item._id);
        }}
      >
        <img
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/prodImages/prod_" +
            item.productId +
            "/" +
            item.productId +
            "_1.jpg"
          }
          className="cartItemImg"
        />
      </span>
      <div className="nonImgCols">
        <div className="nameDescCol">
          <span
            onClick={() => {
              analyParamsCB({
                type: "click",
                pl: {
                  pn: location.pathname,
                  clk: "prodTitleCartItem",
                  field: item.name,
                },
              });
              navigate("/product/" + item._id);
            }}
            className="noDecorationLinkCart"
          >
            <div className="itemNameLable">{item.name}</div>
          </span>
          <div>
            {item.description.length > 50
              ? item.description.slice(0, 50) + "..."
              : item.description}
          </div>
        </div>
        <div className="qtyCol">
          <div className="quantityLabel">Quantity</div>
          <IoRemoveCircleOutline
            size={"18px"}
            onClick={() => setQty((qty) => Math.max(qty - 1, 1))}
          />
          <input
            className="cartQtyInput"
            type="text"
            value={qty}
            onChange={(event) => {
              setQty(
                event.target.value > 10
                  ? 10
                  : event.target.value === "0"
                  ? 1
                  : event.target.value
              );
            }}
            onBlur={(event) => {
              qty === "" && setQty(Number(1));
            }}
          />
          <IoAddCircleOutline
            size={"18px"}
            onClick={() => setQty((qty) => Math.min(qty + 1, 10))}
          />
          <div className="cartDeleteItem">
            <IoTrashBinOutline
              size={"25px"}
              color={"red"}
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "remoteItemCartBox",
                    field: item.name,
                  },
                });
                handleDeleteItem();
              }}
            />
          </div>
        </div>
        <div className="priceCol">
          <div className="priceLabel">Price </div>£
          {Number(item.offerPrice).toLocaleString()}
          <div>
            (<span className="origPriceRow">£ {item.origPrice}</span>)
          </div>
        </div>
        <div className="totalCol">
          <div className="totalLabel">Total </div>
          <div>£ {Number(item.offerPrice * qty).toLocaleString()}</div>

          <div className="cartSavingsItems">
            Savings: £
            {(Number(item.origPrice - item.offerPrice) * qty).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
